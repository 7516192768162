import { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { Channel } from '../../components/Channel';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { NotFound } from '../../components/NotFound';
import { WEEK_DAYS } from '../../constants/week-days';
import { useTitle } from '../../hooks/useTitle.hook';
import { SlackChannel, Standup as StandupModel, User } from '../../interfaces';
import { ROUTES } from '../../routes';
import { Participants } from './components';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { useIsOwner } from '../../hooks/useTeamRole.hook';

export const Standup: FC = () => {
  const isTeamOwner = useIsOwner();
  const selectedTeam = useSelectedTeam();
  const { id } = useParams<{ id: string }>();
  const {
    data: standup,
    isFetching,
    error,
  } = useQuery<StandupModel, Error>('standup', () => api.standups.get(selectedTeam._id, id));
  // const { data: channels } = useQuery<SlackChannel[], Error>('slack channels', api.slack.getChannels);
  // const { data: users } = useQuery<SlackUser[], Error>('slack users', api.slack.getUsers);

  useTitle(standup?.name, { back: ROUTES.Standups() });

  if (isFetching && !standup) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!standup) {
    return <NotFound />;
  }

  return (
    <Row>
      <Col xs="12" md="6">
        <Card body className="mb-3">
          <div>
            {standup.time} ({standup.timezone})
          </div>
          <div>{standup.weekDays.map((day: number) => WEEK_DAYS[day]).join(', ')}</div>
          {/* <Channel channel={standup.channel} slackChannels={channels || []} /> */}
          <Participants users={standup.users as User[]} />
        </Card>
        <div>
          {isTeamOwner && (
            <Link to={ROUTES.EditStandup(standup._id)} className="btn btn-outline-primary me-3">
              Edit
            </Link>
          )}
          <Link to={ROUTES.SendAnswers(standup._id)} className="btn btn-outline-primary">
            Submit Standup
          </Link>
        </div>
      </Col>
    </Row>
  );
};
