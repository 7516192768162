export const ROUTES = {
  Main: (): string => '/',
  Auth: (): string => '/auth',
  Login: (): string => '/auth/login',
  SignUp: (): string => '/auth/sign-up',
  Activate: (token = ':token'): string => `/auth/activate/${token}`,
  Redirect: (token = ':token'): string => `/auth/redirect/${token}`,
  AcceptInvite: (token = ':token'): string => `/auth/invites/${token}/accept`,
  Profile: (): string => '/profile',
  Standups: (): string => '/profile/standups',
  Members: (): string => '/profile/members',
  InviteMember: (): string => '/profile/members/invite',
  Standup: (id = ':id'): string => `/profile/standups/${id}`,
  StandupDay: (id = ':id', dayId = ':dayId'): string => `/profile/standups/${id}/days/${dayId}`,
  SendAnswers: (id = ':id'): string => `/profile/standups/${id}/send-answers`,
  CreateStandup: (): string => '/profile/standups/create',
  EditStandup: (id = ':id'): string => `/profile/standups/${id}/edit`,
  Billing: (): string => '/profile/billing',
  Settings: (): string => '/profile/settings',
  Logout: (): string => '/profile/logout',
};
