import { FC, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Button, Container, Nav } from 'react-bootstrap';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiLogOut, FiLayout, FiUsers, FiDollarSign, FiSettings } from 'react-icons/fi';
import { AxiosError } from 'axios';
import { TitleContext } from '../contexts/title.context';
import { ROUTES } from '../routes';
import { Loading } from '../components/Loading';
import { ErrorMessage } from '../components/ErrorMessage';
import './PanelLayout.scss';
import { useQuery } from 'react-query';
import { User } from '../interfaces';
import { api } from '../api';
import { MeContext } from '../contexts/me.context';
import { SelectedTeamProvider } from '../contexts/selectedTeam.context';
import { UserAvatar } from '../components/UserAvatar';

export const PanelLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [back, setBack] = useState('');
  const [action, setAction] = useState<ReactElement>();

  const { data, error, isFetching } = useQuery<User, AxiosError>('me', api.users.getMe);

  const onBackClick = useCallback(() => {
    history.push(back);
  }, [history, back]);

  if (!data && isFetching) {
    return <Loading />;
  }

  if (error) {
    if (error.response?.status === 401) {
      history.push(ROUTES.Auth());
      return null;
    }

    return <ErrorMessage error={error} />;
  }

  if (!data) {
    return <ErrorMessage error={new Error('No user is logged in')} />;
  }

  return (
    <>
      <div className="d-flex vh-100">
        <div className="d-flex flex-column flex-shrink-0 p-3 panel-menu" style={{ width: '280px' }}>
          <a href="/" className="text-center mb-4 text-decoration-none">
            <span className="fs-4">
              <img src="/CatchUpNinja-1.png" className="header-logo" alt="header-logo" />
            </span>
          </a>
          <div className="mt-4 mb-5 text-center">
            {data && <UserAvatar user={data} />}
            <div className="fw-bolder fs-6">{data?.realName}</div>
            <div className="text-secondary text-lighter fs-6">{data?.name}</div>
          </div>
          <Nav variant="pills" className="flex-column mb-auto">
            <NavLink to={ROUTES.Standups()} className="nav-link d-flex align-items-center py-2 mb-3">
              <FiLayout className="me-3" />
              Dashboard
            </NavLink>
            <NavLink to={ROUTES.Members()} className="nav-link d-flex align-items-center py-2 mb-3">
              <FiUsers className="me-3" />
              Members
            </NavLink>
            {/* <NavLink to={ROUTES.Billing()} className="nav-link d-flex align-items-center py-2 mb-3">
              <FiDollarSign className="me-3" />
              Billing
            </NavLink>
            <NavLink to={ROUTES.Settings()} className="nav-link d-flex align-items-center py-2 mb-3">
              <FiSettings className="me-3" />
              Settings
            </NavLink> */}
          </Nav>
          <div>
            <Link to={ROUTES.Logout()} className="nav-link d-flex align-items-center py-2 mb-3">
              <FiLogOut className="me-3" />
              Log out
            </Link>
          </div>
        </div>
        <div className="vh-100 w-100 d-flex flex-column panel-content">
          {title && (
            <Container className="py-3">
              <div className="d-flex align-items-center justify-content-between px-3">
                <div className="d-flex align-items-center">
                  {back && (
                    <Button variant="link" className="btn-back" onClick={onBackClick}>
                      <FiArrowLeft size="25" className="me-1" />
                    </Button>
                  )}
                  <h1 className="mb-0 panel-title">{title}</h1>
                </div>
                {action}
              </div>
            </Container>
          )}
          <Container className="overflow-auto pt-2 pb-4">
            <div className="px-3">
              <MeContext.Provider value={{ me: data }}>
                <SelectedTeamProvider>
                  <TitleContext.Provider value={{ title, setTitle, setBack, setAction }}>
                    {children}
                  </TitleContext.Provider>
                </SelectedTeamProvider>
              </MeContext.Provider>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
