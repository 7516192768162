import { FC } from 'react';
import { User } from '../../interfaces';
import './UserAvatar.scss';

interface Props {
  user: User;
}

export const UserAvatar: FC<Props> = ({ user }) => {
  if (user.avatar) {
    return <img src={user?.avatar} alt="Avatar" className="panel-avatar img-fluid rounded-circle mb-2" />;
  }

  return (
    <div className="d-inline-block">
      <div className="panel-avatar rounded-circle mb-2 display-4 fw-bold d-flex align-items-center justify-content-center bg-dark text-white">
        {user.realName.substring(0, 2)}
      </div>
    </div>
  );
};
