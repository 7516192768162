import { FC, PropsWithChildren } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import './AuthLayout.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '../routes';

export const AuthLayout: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <Navbar expand="lg" className="bg-white" sticky="top">
      <Container className="justify-content-center">
        <Link to={ROUTES.Profile()} title="Panel">
          <img src="/CatchUpNinja-1.png" className="header-logo" alt="header-logo" />
        </Link>
      </Container>
    </Navbar>
    <div className="auth-content py-4 d-flex flex-column align-items-center justify-content-center">
      <Container>{children}</Container>
    </div>
  </>
);
